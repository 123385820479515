import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  Button,
  Dropdown,
  Modal,
  Form,
  Input,
  Divider,
  message as Message,
  Collapse,
  Space,
  Tooltip,
} from "antd"
import { DownOutlined, MenuOutlined, PhoneFilled } from "@ant-design/icons"

import "./header.less"
import { sendCaseUpdate } from "../api"
import { WEB_APP_URL } from "../constant"
import logo from "../images/logo-text.png"
import Routes from "../constant/routes"

const SERVICES = [
  { title: "Speeding Ticket", type: Routes.SPEEDING_TICKET_LAWYERS },
  { title: "Traffic Ticket", type: Routes.TRAFFIC_TICKET_LAWYERS },
  { title: "DWI or DUI", type: Routes.DUI_LAWYERS },
  { title: "Suspended License", type: Routes.SUSPENDED_LICENSE_LAWYERS },
  { title: "Fake ID", type: Routes.FAKE_ID_LAWYERS },
  { title: "MIP", type: Routes.MIP_LAWYERS },
  { title: "Marijuana", type: Routes.CANNA_TAMER },
  { title: "CDL Services", type: Routes.CDL_LAWYERS },
]

const MenuMobile = ({ visible, onClose, showCaseUpdate }) => {
  return (
    <Modal
      title=""
      footer={null}
      open={visible}
      onCancel={onClose}
      style={{ margin: 0, maxWidth: "100vw", top: 0, position: "fixed" }}
      bodyStyle={{ height: "100vh" }}
    >
      <div className="Mobile__Menu">
        {/* <a style={{width: '100%', marginTop: 20}} href={`${WEB_APP_URL}/login`}><Button block>Log in</Button></a> */}
        <a
          style={{ width: "100%", marginTop: 40, marginBottom: 8 }}
          href={`${WEB_APP_URL}/submit-ticket`}
        >
          <Button type="primary" block>
            Get Instant Quote
          </Button>
        </a>
        <a
          style={{ width: "100%" }}
          href="https://calendly.com/tickettamer/15min?month={YYYY-MM}"
          target="_blank"
          rel="noreferrer"
        >
          <Button block>Free Consultation</Button>
        </a>
        <a
          href="tel:3147284444"
          style={{
            fontSize: 18,
            fontWeight: "bold",
            textAlign: "center",
            width: "100%",
            marginTop: 12,
          }}
        >
          <Tooltip title="(314) 728-4444">
            Call Us
            <PhoneFilled style={{ fontSize: 24, marginLeft: 8 }} />
          </Tooltip>
        </a>
        <Divider />
        <div
          className="ButtonActionInactiveCenter Mobile__Menu__Item"
          onClick={showCaseUpdate}
        >
          Get Case Update
        </div>
        <Collapse ghost>
          <Collapse.Panel
            header={<div className="ButtonActionInactiveLeft">Services</div>}
          >
            {SERVICES.map((s, index) => (
              <Link to={s.type} onClick={onClose} key={`service_${index}`}>
                <div className="BodyGreyLeft Mobile__Menu__Item">{s.title}</div>
              </Link>
            ))}
          </Collapse.Panel>
        </Collapse>

        {/* <Link to={Routes.PRICING} onClick={onClose}>
          <div className="ButtonActionInactiveCenter Mobile__Menu__Item">Pricing</div>
        </Link>
        <Link to={Routes.CANNA_TAMER_PRICING} onClick={onClose}>
          <div className="ButtonActionInactiveCenter Mobile__Menu__Item">CannaTamer Pricing</div>
        </Link> */}
        <Link to={Routes.FAQ} onClick={onClose}>
          <div className="ButtonActionInactiveCenter Mobile__Menu__Item">
            FAQ
          </div>
        </Link>
        <Link to={Routes.RESOURCES} onClick={onClose}>
          <div className="ButtonActionInactiveCenter Mobile__Menu__Item">
            Resources
          </div>
        </Link>
        <Link to={Routes.ABOUT_US} onClick={onClose}>
          <div className="ButtonActionInactiveCenter Mobile__Menu__Item">
            About Us
          </div>
        </Link>
      </div>
    </Modal>
  )
}

const Header = ({}) => {
  const [showMenuModal, setShowMenu] = useState(false)
  const [showCaseUpdateModal, setShowCaseUpdateModal] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async values => {
    setLoading(true)
    const res = await sendCaseUpdate(values)
    if (res) {
      const { message, error } = res
      if (error) {
        Message.error(error)
      } else if (message) {
        setShowCaseUpdateModal(false)
        Modal.success({
          content:
            "Case update was sent to your email inbox, please check for instructions there.",
        })
      }
    }
    setLoading(false)
  }

  const renderCaseUpdateModal = () => {
    return (
      <Modal
        open={showCaseUpdateModal}
        onCancel={() => setShowCaseUpdateModal(false)}
        footer={null}
        bodyStyle={{ padding: 40 }}
      >
        <div className="">
          <div className="BodyBlackLeft mt24 mb24">
            Please provide these information to help us give you the case update
            as soon as possible
          </div>
          <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Your email is required!" }]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              label="Name on Ticket"
              name="ticketNumber"
              rules={[
                { required: true, message: "Your name on ticket is required!" },
              ]}
            >
              <Input placeholder="Enter your name on ticket" />
            </Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Modal>
    )
  }

  const menu = SERVICES.map((i, index) => ({
    label: <Link to={i.type}>{i.title}</Link>,
    key: `service_${index}`,
  }))

  const pricingMenu = [
    {
      label: <Link to={Routes.PRICING}>TicketTamer Pricing</Link>,
      key: `price_0`,
    },
    {
      label: <Link to={Routes.CANNA_TAMER_PRICING}>CannaTamer Pricing</Link>,
      key: `price_1`,
    },
  ]

  return (
    <header className="Header">
      <div className="Header__Container">
        <div className="Header__Left">
          <Link to={Routes.HOME}>
            <img
              src={logo}
              alt="logo"
              style={{ marginBottom: 0, width: 182 }}
            />
          </Link>
          <div className="Header__Left__Nav">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowCaseUpdateModal(true)}
              className="ButtonActionInactiveLeft Link__Item"
            >
              Get Case Update
            </div>
            <Dropdown menu={{ items: menu }}>
              <a
                className="ant-dropdown-link ButtonActionInactiveLeft Link__Item"
                onClick={e => e.preventDefault()}
              >
                Services <DownOutlined />
              </a>
            </Dropdown>
            {/* <Dropdown menu={{items: pricingMenu}}>
            <a className="ant-dropdown-link ButtonActionInactiveLeft Link__Item" onClick={e => e.preventDefault()}>
              Pricing <DownOutlined />
            </a>
          </Dropdown> */}
            <Link
              to={Routes.FAQ}
              className="ButtonActionInactiveLeft Link__Item"
            >
              FAQ
            </Link>
            <Link
              to={Routes.RESOURCES}
              className="ButtonActionInactiveLeft Link__Item"
            >
              Resources
            </Link>
            <Link
              to={Routes.ABOUT_US}
              className="ButtonActionInactiveLeft Link__Item"
            >
              About Us
            </Link>
          </div>
        </div>
        <div className="Header__Right">
          <div className="for-desktop">
            <Space>
              <a
                href="tel:3147284444"
                style={{ fontSize: 14, fontWeight: "bold" }}
              >
                <Tooltip title="(314) 728-4444">
                  Call Us
                  <PhoneFilled style={{ fontSize: 20, marginLeft: 8 }} />
                </Tooltip>
              </a>
              <a href={`${WEB_APP_URL}/login`}>
                <Button type="text">Log in</Button>
              </a>
              <a
                href="https://calendly.com/tickettamer/15min?month={YYYY-MM}"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Free Consultation</Button>
              </a>
              {/* <a href={GET_TICKETTAMER}><Button type="primary">Get Instant Quote</Button></a> */}
              <a href={`${WEB_APP_URL}/submit-ticket`}>
                <Button type="primary">Get Instant Quote</Button>
              </a>
            </Space>
          </div>
          <div
            className="for-mobile mobile-header-button"
            onClick={() => setShowMenu(true)}
          >
            <a href={`${WEB_APP_URL}/login`}>
              <Button type="primary">Log in</Button>
            </a>
            <MenuOutlined style={{ fontSize: 32 }} />
          </div>
        </div>
      </div>
      <MenuMobile
        visible={showMenuModal}
        onClose={() => setShowMenu(false)}
        showCaseUpdate={() => setShowCaseUpdateModal(true)}
      />
      {renderCaseUpdateModal()}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
